import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  connectProviderAction,
  disconnectProviderAction,
} from "modules/auth/actions";
import PropTypes from "prop-types";
import GoogleLoginButton from "app/components/Button/LoginButtons/GoogleLoginButton";
import Alert from "app/components/Alert";
import Button from "app/components/Button";
import { SectionWrapper } from "./styles";

const H3 = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const P = styled.p`
  margin: 10px 0;
  font-style: italic;
  font-size: 14px;
  font-weight: 600;
`;

const Avatar = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const StyledButton = styled(Button)`
  padding: 4px;
  font-size: 12px;
`;

class GoogleConnect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  unlinkProvider = () => {
    const { unlinkProvider } = this.props;

    unlinkProvider("google")
      .then(() => {
        this.setState({
          error: null,
        });
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  linkProvider = () => {
    const { linkProvider } = this.props;

    linkProvider("google")
      .then(() => {
        this.setState({
          error: null,
        });
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  render() {
    const { providerData } = this.props;
    const { error } = this.state;
    const emailProviderData = providerData.find(
      data => data.providerId === "password"
    );
    const googleProviderData = providerData.find(
      data => data.providerId === "google.com"
    );

    return (
      <SectionWrapper>
        <div>
          <H3>Connect Google</H3>
          {!googleProviderData && (
            <GoogleLoginButton width="100%" onClick={this.linkProvider} />
          )}
        </div>
        {googleProviderData && (
          <div>
            <Avatar
              alt="Google Avatar"
              src={`${googleProviderData.photoURL}?height=200&width=200`}
            />
            <P>Connected as {googleProviderData.displayName}</P>
            {emailProviderData && (
              <StyledButton variant="danger" onClick={this.unlinkProvider}>
                Unlink Google Account
              </StyledButton>
            )}
          </div>
        )}
        {error && (
          <Alert
            variant="danger"
            closeAlert={() => this.setState({ error: null })}
          >
            {error.message}
          </Alert>
        )}
      </SectionWrapper>
    );
  }
}

const mapDispatchToProps = {
  unlinkProvider: disconnectProviderAction,
  linkProvider: connectProviderAction,
};

GoogleConnect.defaultProps = {
  providerData: [],
};

GoogleConnect.propTypes = {
  providerData: PropTypes.instanceOf(Array),
  linkProvider: PropTypes.func.isRequired,
  unlinkProvider: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(GoogleConnect);
